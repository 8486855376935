<template>
  <!-- 查看系统的对话框 -->
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据管理</el-breadcrumb-item>
      <!--      <el-breadcrumb-item :to="{ path: '/dataManage-dataList/dataList' }">{{preCrumb}} 系统数据列表</el-breadcrumb-item>-->
      <el-breadcrumb-item><span @click="back()" style="font-weight: bold">{{preCrumb}} 系统数据列表</span> </el-breadcrumb-item>
      <el-breadcrumb-item>{{currentSystem}} 设备数据列表</el-breadcrumb-item>
    </el-breadcrumb>
    <over-view-of-sub-system  :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-sub-system>
    <span style="font-size: 16px;">搜索：</span>
    <el-select v-model="currentSubSystemId" placeholder="请选择子系统" @change="selectSubsystem()" style="width: 400px">
      <el-option v-for="item in options" :key="item.id" :label="item.subSystem" :value="item.id">
      </el-option>
    </el-select>

    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
    >
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="设备id"
          header-align="center"
          align="center"
          min-width="5%"
          v-if=false
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="设备"
          header-align="center"
          align="center"
          min-width="5%">
      </el-table-column>
      <el-table-column
          prop="subEquipmentCount"
          label="附属设备个数"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <!--      <el-table-column prop="measurePointNum" label="数据项个数" header-align="center" align="center" width="180">-->
      <!--      </el-table-column>-->
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
          <!--          >查看</el-button>  &lt;!&ndash; 这里要改，当子系统个数为0时，查看按钮不可用 &ndash;&gt;-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%">
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <div style="display: flex;justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkSystem",
  data() {
    return {
      visible: false,
      options: [],
      tableData: [],
      page:1,  //当前第几页
      size:10, //当前每页页数
      total:0, //全部数据量
      currentSystem: '',
      currentSystemId: 0,
      currentSubSystemId:'',
      currentsubSystem:'',
      dataListLoading: false,
      overViewFlag: false,
      preCrumb: ''
    }
  },
  mounted(){
    this.currentSystemId = this.$route.query.systemId
    this.currentSystem = this.$route.query.systemName
    this.preCrumb = this.$route.query.crumb
    this.getSubsystemOption()
  },
  methods: {
    selectSubsystem(){
      for (let i in this.options) {
        if (this.options[i].id == this.currentSubSystemId){
          this.currentsubSystem = this.options[i].subSystem;
          break;
        }
      }
      this.initData();
    },
    getSubsystemOption(){
      this.getRequest('/dataList/getSubsystemOptionBySystemId/?systemId=' + this.currentSystemId).then(resp=>{
        if (resp.data){
          this.options = resp.data;
          this.currentsubSystem = this.options[0].subSystem;
          this.currentSubSystemId = this.options[0].id;
          this.initData()
        }
      });
    },
    initData(){
      this.dataListLoading = true
      this.getRequest(
          "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" +
          this.currentSubSystemId + "&page=" + this.page + "&size=" + this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    // 监听 pagesize 改变的事件
    currentChange(currentPage){
      this.page=currentPage;
      this.initData();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initData();
    },
    // init(id) {
    //     this.visible = true;
    // }
    handleCheck(equip) {
      window.sessionStorage.setItem("dataList_currentEquipId",equip.id); //存到sessionStorage中，下一个页面获取
      this.$router.push({
        name: 'dataDetail',
        query: {
          equipmentId: equip.id,
          systemId: this.$route.query.systemId,
          pCrumb: this.currentSystem,
          dpCrumb: this.preCrumb,
          crumb: equip.name
        }
      })
    },
    back() {
      history.back()
    }

  }
}
</script>


<style scoped>

</style>
